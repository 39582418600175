import { navigate } from 'gatsby';
import React, { useEffect } from 'react';
import { setMagazineCookies } from 'utils/cookies';
import { ROUTE_SOFTWARE } from 'shared/paths';

// This is redirection from QR code with additional tracking
export default () => {
  useEffect(() => {
    setMagazineCookies();
    navigate(ROUTE_SOFTWARE);
  }, []);

  return <></>;
};
